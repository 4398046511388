import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import utc from "dayjs/plugin/utc";

import React from "react";
import "./App.css";
import "./print.css";
import locale from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import Entry from "components/Entry";
import 'dayjs/locale/es' 

import Chart from "chart.js/auto"
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {getChartLabelPlugin} from "chart.js-plugin-labels-dv"
import { Provider } from "react-redux";
import configureStore from "./Redux/config";

const store = configureStore();
sessionStorage.setItem("redirect", window.location.pathname);

dayjs.locale("es");
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

Chart.register(getChartLabelPlugin(),ChartDataLabels);

/*
var AWS = require('aws-sdk/dist/aws-sdk-react-native');
AWS.config.region = 'us-east-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-2:dce46dd0-9daf-4fcf-bb5d-a6bc341887ee'
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: 'jagsas' }
});

s3.listObjects({ Delimiter: '/' }, function (err, data) {
  console.log(err, data);
  var href = this.request.httpRequest.endpoint.href;
  var bucketUrl = href + 'jagsas/';
  data.Contents.forEach(d => {
    var photoKey = d.Key;
    var photoUrl = bucketUrl + encodeURIComponent(photoKey);
    console.log(photoUrl);
  });
});*/

function App() {
  console.log("APP ",process.env.REACT_APP_APP)
  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <Entry />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
