import {
  MARKER_LOADING,
  MARKER_LOAD_SUCCESS,
  MARKER_LOAD_FAIL,
} from "Redux/actions";
import produce from "immer";

const initial_state = { docs: [], loading: false };

const Marker = (state = initial_state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case MARKER_LOADING:
        draft.loading = true;
        break;
      case MARKER_LOAD_SUCCESS:
        draft.docs = action.docs;
        draft.loading = false;
        break;
      case MARKER_LOAD_FAIL:
        draft.loading = false;

        break;
    }
  });
};
export default Marker;
