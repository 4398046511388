import AccessDenied from "ComponentsGlobal/AccessDenied"
import { Button, Popconfirm, Space, Table, message } from "antd"
import Body from "components/Body"
import Policy from "components/Policy"
import { useState } from "react"
import Navbar from "./Navbar"
import RefrigeranteGuardar from "../Guardar"
import API from "Util/Axios"
import {EditOutlined,DeleteOutlined} from '@ant-design/icons'

const RefrigranteListado=()=>{

    const [docs,setDocs] = useState([])
    const [doc,setDoc] = useState(null)
    const [cargando,setCargando] = useState(false)

    const cargar=async ()=>{
        try{
            setCargando(true)
            const {data} = await API.get("refrigerante")
            setDocs(data.data)
        }catch(error){
            message.error(error.toString())
        }finally{
            setCargando(false)
        }
    }

    const init=()=>{
        cargar()
    }

    const borrar=async (_doc)=>{
        try{
            setCargando(true)
            await API.delete(`refrigerante/${_doc._id}`)
            setDocs(prev=>prev.filter(p=>p._id!=_doc._id))
            message.success('Registro borrado')
        }catch(error){
            message.error(error.toString())
        }finally{
            setCargando(false)
        }
    }

    const onGuardado=(payload)=>{
        if(docs.some(d=>d._id==payload._id)){
            setDocs(prev=>prev.map(p=>{
                if(p._id==payload._id){
                    return payload
                }
                return p
            }))
        }else{
            setDocs(prev=>[...prev,payload])
        }
    }

    return <Policy
            policy={["refrigerante:view"]}
            init={init}
            feedback={<AccessDenied msn="No tienes acceso al modulo Refrigerantes" />}
        >
        <Body  
        style_content={{ padding: 16 }}
        navbar_content={<Navbar onNuevo={()=>setDoc({})} onReload={cargar}/>}
        >
            <RefrigeranteGuardar doc={doc} setDoc={setDoc} onGuardado={onGuardado}/>
            <Table rowKey="_id" dataSource={docs} loading={cargando}>
                <Table.Column title="Nombre" dataIndex={'nombre'} />
                <Table.Column title="Acciones" render={(_doc)=><Space>
                    <Policy policy={["refrigerante:edit"]}>
                        <Button shape="circle" onClick={()=>setDoc(_doc)} icon={<EditOutlined/>}/>
                    </Policy>
                    <Policy policy={["refrigerante:delete"]}>
                        <Popconfirm title="Se borrara el registro" onConfirm={()=>borrar(_doc)}>
                            <Button shape="circle" icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </Policy>
                </Space>}/>
            </Table>
        </Body>
    </Policy>
}

export default RefrigranteListado