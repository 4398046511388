import * as Path from "Path/index";

export const customer = [
  {
    policy: "customer:view",
    name: "Clientes",
    href: Path.pathCustomers.list,
    img: "imagenes/icons/customers.svg",
    help: "Gestiona sucursales, equipos y cuentas de monitoreo por cada cliente",
  },
];

export const configModules = [
  {
    policy: "jobPosition:view",
    name: "Puestos de trabajo",
    href: Path.pathConfig.job_positions,
    img: "	https://cdn-icons-png.flaticon.com/512/4899/4899329.png",
    help: "Distribuye a tu equipo en puestos de trabajo Ex: puesto de trabajo barranquilla",
  },
  {
    policy: "roles:view",
    name: "Categorias de usuario",
    href: Path.pathConfig.category_user,
    img: "https://cdn-icons-png.flaticon.com/512/1357/1357616.png",
    help: "Crea roles para restringir el acceso a modulos en la plataforma",
  },
  {
    policy: "refrigerante:view",
    name: "Refigerantes",
    href: Path.pathConfig.refrigerantes,
    img: "https://cdn-icons-png.flaticon.com/512/1793/1793260.png",
  },
  {
    policy: "equipmentType:view",
    name: "Tipo de equipos",
    href: Path.pathConfig.type_equipments,
    img: "https://cdn-icons-png.flaticon.com/512/10398/10398810.png",
  },
  {
    policy: "orderType:view",
    name: "Tipo de ordenes",
    href: Path.pathConfig.type_orders,
    img: "	https://cdn-icons-png.flaticon.com/512/3541/3541410.png",
  },

  {
    policy: "feedback:admin",
    name: "Feedback",
    href: Path.pathFeedback,
    img: "imagenes/icons/estudio.svg",
    help: "Configura encuesta que tus clientes responderan al momento de recibir mantenimientos",
  },
  {
    policy: "site:admin",
    name: "Plataforma",
    href: Path.pathConfigSite,
    img: "   https://cdn-icons-png.flaticon.com/512/922/922699.png ",
    help: "Configura aspectos generales de tu empresa",
  },
  {
    policy: "site:admin",
    name: "Causa Raíz",
    href: Path.pathConfig.causa_raiz,
    img: "https://cdn-icons-png.flaticon.com/512/3867/3867669.png",
  },
];

export const programmerModules = [
 
  {
    policy: "roadmap:admin",
    name: "Hojas de rutas",
    href: Path.pathRoadmap.list,
    img: "https://cdn-icons-png.flaticon.com/512/10301/10301417.png",
    help: "Configura las tareas y actividades de los mantenimientos",
  },
  {
    policy: "maintenancePlan",
    name: "Planifica",
    href: Path.pathMaintenancePlan.list,
    img: "https://cdn-icons-png.flaticon.com/512/7756/7756169.png",
    help: "Planifica mttos preventivos usando hojas de rutas",
  },
  {
    policy: "maintenanceSchedule",
    name: "programa",
    href: Path.pathSchedulePlans,
    img: "	https://cdn-icons-png.flaticon.com/512/868/868786.png",
    help: "Crea ordenes de trabajo para tus planes de mantenimiento preventivo",
  },
  {
    policy: "order:admin",
    name: "Calendario",
    href: Path.pathOrder.calendar,
    img: "	https://cdn-icons-png.flaticon.com/512/11195/11195087.png",
  },
  {
    policy: "order:admin",
    name: "Ordenes",
    href: Path.pathOrder.list,
    img: "	https://cdn-icons-png.flaticon.com/512/1157/1157026.png",
  },
  {
    policy: "",
    name: "Historico de archivos",
    href: "/historico-archivos",
    img: "https://cdn-icons-png.flaticon.com/512/2659/2659360.png",
  },
];

export const technicalModules = [
  {
    policy: "",
    name: "Ordenes asignadas",
    href: Path.pathMyOrders,
  },
];

export const monitoringModules = [
  {
    policy: "user:view",
    name: "Usuarios",
    href: Path.pathConfig.user,
    img: "imagenes/icons/users.svg",
  },
  {
    policy: "user:monitor",
    name: "Monitoreo",
    href: Path.pathMonitoringUser,
    img: "imagenes/icons/geolocalizacion.svg",
  },
  {
    policy: "user:locations",
    name: "Informe ubicaciones",
    href: Path.pathMonitoringLocation,
    img: "imagenes/icons/user.png",
  },
  {
    policy: "user:locations",
    name: "Cuadro de horas extras",
    href: "/horas-extras",
    img: "imagenes/icons/horas-extras.png",
  },
];

export const cotizacionModulos = [
  {
    policy: "",
    name: "Dashboard",
    href: Path.rutaCotizacionesDashboard,
    img: "https://cdn-icons-png.flaticon.com/512/1340/1340311.png",
  },
  {
    policy: "",
    name: "Cotizaciones",
    href: Path.rutaCotizaciones,
    img: "	https://cdn-icons-png.flaticon.com/512/4993/4993414.png",
  },
  {
    policy: "",
    name: "Medidas",
    href: Path.rutaUnidades,
    img: "https://cdn-icons-png.flaticon.com/512/3251/3251012.png",
  },
  {
    policy: "",
    name: "Estados",
    href: Path.rutaCotizacionEstado,
    img: "https://cdn-icons-png.flaticon.com/512/744/744485.png",
  },
  {
    policy:"",
    name:"Unidades de Negocios",
    href:Path.rutaCotizacionesUN,
    img:'https://cdn-icons-png.flaticon.com/512/539/539576.png'
  }
];

export const purchasesModules = [
  {
    policy: "costCenter:view",
    name: "Centros de costo",
    href: Path.pathCostCenters,
    img: "imagenes/icons/money.svg",
  },
  /*
  {
    policy: "purchase:inventory",
    name: "Inventario",
    href: Path.pathInventory,
    img: "imagenes/icons/warehouse.svg",
  },*/
  {
    policy: "purchase:warehouse",
    name: "Bodegas",
    href: Path.pathWarehouse,
    img: "imagenes/icons/warehouse.svg",
  },
  {
    policy: "tool:view",
    name: "Herramientas",
    href: Path.pathTools,
    img: "imagenes/icons/tool-box.svg",
  },
  {
    policy: "material:view",
    name: "Materiales",
    href: Path.pathMaterial,
    img: "imagenes/icons/piece.svg",
  },
  {
    policy: [
      "material-order:create",
      "material-order:update",
      "material-order:operation",
      "material-order:buy",
    ],
    name: "Solicitudes de materiales",
    href: Path.pathOrderMaterial,
    img: "imagenes/icons/box.svg",
  },

  {
    policy: "tool-order:view",
    name: "Solicitudes de herramientas",
    href: Path.pathToolOrder,
    img: "imagenes/icons/table.svg",
  },
];

export const reports = [
  {
    policy: "report:maintenance",
    name: "Mantenimientos",
    href: Path.pathReportMaintenances,
    img: "imagenes/icons/report-maintenance.svg",
  },
  {
    policy: "report:box",
    name: "Caja menor",
    href: Path.pathReportMinorBox,
    img: "https://cdn-icons-png.flaticon.com/512/2704/2704312.png",
  },
  {
    name:'Causa Raiz',
    policy:"report:maintenance",
    href: Path.rutaReporteCausaRaiz,
    img:'https://cdn-icons-png.flaticon.com/512/3867/3867669.png'
  }
];

export const project = [
  {
    policy: "project:view",
    name: "Proyectos",
    href: Path.pathProjects,
    img: "imagenes/icons/proyecto.svg",
  },
  {
    policy: "project:view",
    name: "Actividades proyectos",
    href: Path.pathProjectsActivities,
    img: "imagenes/icons/files.png",
  },
];

export const design = [
  {
    policy: "design:view",
    name: "Diseño",
    href: Path.pathDesign,
    img: "imagenes/icons/3d.svg",
  },
];
