import React, { Suspense, useEffect } from "react";
import { Spin } from "antd";

import { BrowserRouter, Routes , Route, Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { io } from "socket.io-client";
import Login from "components/Login";
import Modules from "components/home/index";
import Loading from "components/Loading";
import * as PolicyProvider from "components/Policy/Ctx";
import {
  pathCustomers,
  pathSubsidiary,
  pathCustomerUsers,
  pathConfig,
  pathMaintenancePlan,
  pathRoadmap,
  pathSchedulePlans,
  pathOrder,
  pathMyOrders,
  pathMyEquipments,
  pathMonitoringUser,
  pathCostCenters,
  pathMaterial,
  pathOrderMaterial,
  pathTools,
  pathWarehouse,
  pathToolOrder,
  pathReportMaintenances,
  pathConfigSite,
  pathFeedback,
  pathMonitoringLocation,
  pathProjects,
  pathDesign,
  pathPrintVisit,
  pathEditVisit,
  pathReportMinorBox,
  pathMyMaintenances,
  pathInventory,
  rutaCotizaciones,
  rutaUnidades,
  rutaCotizacionEstado,
  pathProjectsActivities,
  rutaReporteCausaRaiz,
  rutaCotizacionesDashboard,
  rutaCotizacionesUN,
} from "Path";
import { URL_SOCKET } from "Constants";
import ArchivoMantenimientos from "./Archivos";
import RefrigranteListado from "./Refrigerante/Listado";

const pages = [
  {
    path: "/",
    props: { exact: true },
    component: Modules,
  },
  {
    path: "/configuracion/refrigerante/listado",
    props: { exact: true },
    component: RefrigranteListado,
  },
  {
    path: "/historico-archivos",
    props: { exact: true },
    component: ArchivoMantenimientos,
  },
  {
    path: "/horas-extras",
    props: { exact: true },
    component: React.lazy(() =>
      import("components/userLocations/HorasExtras/Listado.js")
    ),
  },
  {
    path: "/customer/order",
    props: {},
    component: React.lazy(() => import("components/customerOrder")),
  },
  {
    path: rutaCotizacionEstado,
    props: { exact: true },
    component: React.lazy(() => import("components/cotizacionEstados/Listado")),
  },
  {
    path: rutaUnidades,
    props: { exact: true },
    component: React.lazy(() => import("components/unidadMedida/Listado")),
  },
  {
    path: rutaCotizaciones,
    props: { exact: true },
    component: React.lazy(() => import("components/Cotizacion/Listado")),
  },
  {
    path: pathInventory,
    props: { exact: true },
    component: React.lazy(() => import("components/inventory/list")),
  },
  {
    path: pathMyMaintenances,
    props: { exact: true },
    component: React.lazy(() => import("components/maintenance/list")),
  },
  {
    path: pathEditVisit,
    props: { exact: true },
    component: React.lazy(() => import("components/projectVisit/edit")),
  },
  {
    path: pathPrintVisit,
    props: { exact: true },
    component: React.lazy(() => import("components/projectVisit/print")),
  },
  {
    path: pathDesign,
    props: { exact: true },
    component: React.lazy(() => import("components/projectDesign/List")),
  },
  {
    path: pathProjects,
    props: { exact: true },
    component: React.lazy(() => import("components/project/List")),
  },
  {
    path: pathProjectsActivities,
    props: { exact: true },
    component: React.lazy(() => import("components/project/Activities/List")),
  },
  {
    path: pathFeedback,
    props: { exact: true },
    component: React.lazy(() => import("components/feedback")),
  },
  {
    path: pathConfigSite,
    props: { exact: true },
    component: React.lazy(() => import("components/configSite")),
  },
  {
    path: pathReportMinorBox,
    props: { exact: true },
    component: React.lazy(() => import("components/report/minorBox")),
  },
  {
    path: pathReportMaintenances,
    props: { exact: true },
    component: React.lazy(() => import("components/report/maintenances")),
  },
  {
    path: pathToolOrder,
    props: { exact: true },
    component: React.lazy(() => import("components/toolOrder/list")),
  },
  {
    path: pathWarehouse,
    props: { exact: true },
    component: React.lazy(() => import("components/warehouse/list")),
  },
  {
    path: pathTools,
    props: { exact: true },
    component: React.lazy(() => import("components/tools/list")),
  },
  {
    path: pathOrderMaterial,
    props: { exact: true },
    component: React.lazy(() => import("components/materialOrder")),
  },
  {
    path: pathMaterial,
    props: { exact: true },
    component: React.lazy(() => import("components/materials")),
  },
  {
    path: pathCostCenters,
    props: { exact: true },
    component: React.lazy(() => import("components/costCenters")),
  },
  {
    path: pathMyEquipments,
    props: { exact: true },
    component: React.lazy(() => import("components/customerEquipments")),
  },
  {
    path: pathMonitoringLocation,
    props: { exact: true },
    component: React.lazy(() => import("components/userLocations")),
  },
  {
    path: pathMaintenancePlan.list,
    props: { exact: true },
    component: React.lazy(() => import("components/maintenancePlan/List")),
  },
  {
    path: pathMonitoringUser,
    props: { exact: true },
    component: React.lazy(() => import("components/userMonitoring")),
  },
  {
    path: pathRoadmap.list,
    props: { exact: true },
    component: React.lazy(() => import("components/Roadmap/List")),
  },
  {
    path: pathMyOrders,
    props: { exact: true },
    component: React.lazy(() => import("components/Order/My")),
  },
  {
    path: pathOrder.list,
    props: { exact: true },
    component: React.lazy(() => import("components/Order/List")),
  },
  {
    path: pathOrder.calendar,
    props: { exact: true },
    component: React.lazy(() => import("components/OrderCalendar/")),
  },
  {
    path: pathSchedulePlans,
    props: { exact: true },
    component: React.lazy(() => import("components/schedulePlans/List")),
  },
  {
    path: pathConfig.user,
    props: { exact: true },
    component: React.lazy(() => import("components/user/List")),
  },
  {
    path: pathConfig.category_user,
    props: { exact: true },
    component: React.lazy(() => import("components/categoryUser/List")),
  },
  {
    path: pathConfig.job_positions,
    props: { exact: true },
    component: React.lazy(() => import("components/jobPosition/List")),
  },
  {
    path: pathConfig.type_orders,
    props: { exact: true },
    component: React.lazy(() => import("components/Order-type/List")),
  },
  {
    path: pathConfig.type_equipments,
    props: { exact: true },
    component: React.lazy(() => import("components/EquipmentType/List")),
  },
  {
    path: pathSubsidiary.equipments,
    props: { exact: true },
    component: React.lazy(() => import("components/subsidiaryEquipment/List")),
  },
  {
    path: pathCustomerUsers.list,
    props: { exact: true },
    component: React.lazy(() => import("components/customerUser/List")),
  },
  {
    path: pathSubsidiary.list,
    props: { exact: true },
    component: React.lazy(() => import("components/subsidiary/List")),
  },
  {
    path: pathCustomers.equipments,
    props: { exact: true },
    component: React.lazy(() => import("components/customer/Equipments")),
  },
  {
    path: pathCustomers.list,
    props: { exact: true },
    component: React.lazy(() => import("components/customer/List")),
  },
  {
    path:pathConfig.causa_raiz,
    props:{exact:true},
    component:React.lazy(()=>import("components/CausaRaiz/Listado"))
  },
  {
    path:rutaReporteCausaRaiz,
    props:{exact:true},
    component:React.lazy(()=>import("components/CausaRaiz/Reporte"))
  },{
    path:rutaCotizacionesDashboard,
    props:{exact:true},
    component:React.lazy(()=>import("components/Cotizacion/Dashboard"))
  },{
    path:rutaCotizacionesUN,
    props:{exact:true},
    component:React.lazy(()=>import("components/Cotizacion/UnidadNegocio/Listado"))
  }
];

function Entry(props) {
  console.log("Entry")
  const { authed, validatingToken } = props;
  //sessionStorage.getItem("redirect")
  let redirect = null;
  redirect = "/";
  /*redirect && redirect != "" && redirect != "/" && redirect != "/login"
      ? redirect
      : "/";*/

  const Home = () => (
    <PolicyProvider.Provider>
      <BrowserRouter>
        <Routes>
          {pages.map((page, i) => (
            <Route
              key={i}
              path={page.path}
              {...page.props}
              element={<Suspense
                  fallback={
                    <Spin>
                      <div style={{ width: "100%", height: "100vh" }}></div>
                    </Spin>
                  }
                >
                  <page.component />
                </Suspense>}
            >
            </Route>
          ))}
        </Routes>
      </BrowserRouter>
    </PolicyProvider.Provider>
  );

  const LoginState = () => (
<Login/>
  );

  useEffect(() => {
    global.socket = io(URL_SOCKET, { transports: ["websocket"] });
  }, []);

  console.log({validatingToken,authed})

  return (
    <>
      {!authed && !validatingToken ? (
        LoginState()
      ) : !validatingToken ? (
        Home()
      ) : (
        <Loading />
      )}
    </>
  );
}

const mapToState = (state) => {
  return {
    authed: state.User.authed,
    name: state.User.name,
    validatingToken: state.User.validatingToken,
  };
};

export default connect(mapToState)(Entry);
