import React, { useState, createContext, useEffect } from "react";
import API from "Util/Axios";

export const Context = createContext();

export const Provider = ({ children }) => {
  const [allPolicies, setAllPolcies] = useState([]);
  const [policies, setPolcies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState(null)

  useEffect(() => {
    console.log("Load policies");
    setError(null)
    Promise.all([
      API.get("user/policies"),
      API.get("policy")
    ]).then(
      (response) => {
        setPolcies(response[0].data);
        setAllPolcies(response[1].data);
        setLoading(false);
      }
    ).catch(error=>{
      console.log('Policy ',error)
      setError(error)
      localStorage.removeItem("token");
      window.location.reload();
    }).finally(()=>{
      setLoading(false);
    });
  }, []);

  const ctx = {
    policies: policies,
    allPolicies: allPolicies,
    policy_error:error
  };

  return (
    <Context.Provider value={ctx}>
      {loading ? "..." : children}
    </Context.Provider>
  );
};
