import React from "react";
import {
  Layout,
  Avatar,
  Space,
  Dropdown,
  Menu,
  Typography,
  Switch,
  Button,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { getUser } from "Util/index";
import { API_URL, CDN } from "Constants";
import MarkerSwitch from "components/marker/switch";
const { Header } = Layout;
const { Title } = Typography;

function Navbar(props) {
  const cerrar = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const { showSwitchMarker = true } = props;

  const User = getUser();

  const menu =[
    {
      key:2,
      label:<Button type="link" icon={<LogoutOutlined />} onClick={cerrar}>
            Salir
           </Button>
    }
  ] 
  // (
  //   <Menu>
  //     <Menu.Item>Perfil</Menu.Item>
  //     <Menu.Divider></Menu.Divider>
  //     <Menu.Item icon={<LogoutOutlined />} onClick={cerrar}>
  //       Salir
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <Header style={{ display: "flex" }}>
      <Space size="middle" align="center" style={{ marginRight: 16 }}>
        <img
          src={`${CDN}/website/logo.png?t=${props.general.logo}`}
          style={{ width: 100 }}
        />
        <Title level={4} style={{ marginTop: 8 }}></Title>
      </Space>

      {props.children}

      <Space align="center" style={{ marginLeft: "auto" }}>
        {props.right}
        {showSwitchMarker && <MarkerSwitch />}
        <Dropdown menu={{items:menu}} trigger={["click"]}>
          <Space size="small" align="end" style={{ marginLeft: 16 }}>
            <Avatar icon={<UserOutlined />} />
            <b style={{ color: "white" }}>{User.name}</b>
          </Space>
        </Dropdown>
      </Space>
    </Header>
  );
}

const mapToState = (state) => {
  return { general: state.General };
};

export default connect(mapToState)(Navbar);
