import { MARKER_LOADING, MARKER_LOAD_SUCCESS, MARKER_LOAD_FAIL } from "./index";
import API from "Util/Axios";

export const load = () => {
  return (dispatch) => {
    dispatch({ type: MARKER_LOADING });
    API("marker")
      .then((res) => {
        dispatch({ type: MARKER_LOAD_SUCCESS, docs: res.data });
      })
      .catch((error) => {
        dispatch({ type: MARKER_LOAD_FAIL });
      });
  };
};
