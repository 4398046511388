import { combineReducers } from "redux";
import User from "./User";
import Customer from "./Customer";
import MyOrders from "./MyOrders";
import General from "./General";
import Marker from "./Marker";

export default combineReducers({
  User: User,
  Customer: Customer,
  MyOrders: MyOrders,
  General: General,
  Marker: Marker,
});
