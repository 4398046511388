import React, { useState, useEffect } from "react";
import { Tag, Space, Popover, Descriptions, Skeleton,Typography } from "antd";
import API from "Util/Axios";

const {Text}=Typography
const CustomerTag = (props) => {
  const [doc, setDoc] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props._id) {
      setLoading(true);
      API.get(
        'crud/findOne?c=customers&f={"_id":"' + props._id + '"}&p={"name":1,"email":1,"telephone":1,"address":1}'
      ).then((r) => {
        console.log(r);
        setDoc(r.data);
        if(props.setCustomer){
          props.setCustomer(r.data);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    }
  }, [props._id]);

  const renderContent = () => {
    switch (props.mode) {
      case "text":
        return <Text {...props.text}>{doc && doc.name}</Text>;
      default:
        return <Tag>{doc && doc.name}</Tag>;
        break;
    }
  };

  return (
    <Space align="baseline">
      {!loading ? (
        renderContent()
      ) : (
        <Skeleton.Input active={true} style={{ width: 100, height: 20 }} />
      )}
    </Space>
  );
};
export default CustomerTag;
