import API from "Util/Axios";
import { Button, DatePicker, Form, Select, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const Filtros = ({ filtrar, cargando }) => {
  const [form] = Form.useForm();

  const [users,setUsers]=useState([])

  const cargarUsuarios=async ()=>{
    try{
      const {data} = await API.get("user");
      setUsers(data.map(d=>({value:d._id,label:d.name})))
    }catch(error){

    }
  }

  useEffect(() => {
    API('user')
    form.setFieldsValue({ fechas: [dayjs().subtract(1, "days"), dayjs()],user:'' });
    cargarUsuarios()
    //form.submit();
  }, []);

  return (
    <Spin spinning={cargando}>
      <Form
        onFinish={(data) => {
          console.log(data);
          filtrar({
            start: data.fechas[0].toDate(),
            end: data.fechas[1].toDate(),
            user:data.user
          });
        }}
        form={form}
        layout="inline"
      >
        <Form.Item
          name="fechas"
          label="Rango de fechas"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item name="user" label="Usuario">
          <Select style={{width:200}}>
            <Select.Option value="">Todos</Select.Option>
            {users.map(u=><Select.Option value={u.value}>{u.label}</Select.Option>)}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Consultar
        </Button>
      </Form>
    </Spin>
  );
};

export default Filtros;
