import { Badge, Card, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import API from "Util/Axios";

const BadgeModule = ({ href }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  console.log(href);

  const loadCountOrderMaterial = () => {
    setLoading(true);
    API("material-order/count")
      .then(({ data }) => {
        setCount(data.count);
      })
      .finally(() => setLoading(false));
  };

  const loadCountOrderTool = () => {
    setLoading(true);
    API("toolOrder/count")
      .then(({ data }) => {
        setCount(data.count);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (href == "/accountant/orders-materials") {
      loadCountOrderMaterial();
    }
    if (href == "/accountant/tool-order") {
      loadCountOrderTool();
    }
  }, []);

  if (loading) {
    return (
      <Spin
        spinning={true}
        style={{ position: "absolute", top: -12, right: -46 }}
      ></Spin>
    );
  }

  if (count == 0) {
    return null;
  }

  return (
    <Badge
      count={count}
      style={{ position: "absolute", top: -12, right: -46 }}
      overflowCount={999}
    ></Badge>
  );
};

export default BadgeModule;
