export const pathCustomers = {
  list: "/customers",
  detail: "/customers/:id",
  equipments: "/customers/:id/equipments",
};
export const pathCustomerUsers = {
  list: "/customer/:customer/users",
};
export const pathSubsidiary = {
  list: "/customer/:customer/subsidiarys",
  detail: "/subsidiary/:id",
  equipments: "/customer/:customer/subsidiary/:subsidiary/equipments",
};
export const pathPact = {
  list: "/customer/:customer/subsidiary/:subsidiary/pacts",
};

export const pathConfig = {
  type_equipments: "/equipment-types",
  type_orders: "/order-types",
  job_positions: "/job-positions",
  category_user: "/category-user",
  user: "/user",
  policy: "/policy",
  refrigerantes:"/configuracion/refrigerante/listado",
  causa_raiz:"/configuracion/causa-raiz"
};

export const pathMaintenancePlan = {
  list: "/maintenance-plan",
  save: "/maintenance-plan/:id",
};

export const pathRoadmap = {
  list: "/roadmaps",
  save: "/roadmaps/:id",
};

export const pathOrder = {
  list: "/order-list",
  calendar: "/order-calendar",
};

export const pathMyOrders = "/my-orders";

export const pathSchedulePlans = "/schedule-plans";

export const pathMyEquipments = "/my-equipments";
export const pathMyMaintenances = "/my-maintenance";

export const pathMonitoringUser = "/users/monitoring";
export const pathMonitoringLocation = "/monitoring/report-location";

export const pathCostCenters = "/accountant/cost-centers";

export const pathMaterial = "/accountant/materials";

export const pathOrderMaterial = "/accountant/orders-materials";

export const pathTools = "/accountant/tools";

export const pathWarehouse = "/accountant/warehouse";

export const pathToolOrder = "/accountant/tool-order";

export const pathInventory = "/accountant/warehouse/:warehouse/inventory";

export const pathReportMaintenances = "/report/maintenances";
export const pathReportMinorBox = "/report/minor-box";
export const rutaReporteCausaRaiz='/report/root-cause'

export const pathConfigSite = "/config-site";

export const pathFeedback = "/config/feedback";

export const pathProjects = "/projects";
export const pathProjectsActivities = "/projects/activities";

export const pathDesign = "/design";

export const pathPrintVisit = "/project/:project/visit/:visit";

export const pathEditVisit = "/project/:project/visit/:visit/edit";

export const rutaCotizaciones = "/marker-rates";
export const rutaCotizacionesDashboard = "/marker-rates/dashboard";
export const rutaCotizacionesUN = "/marker-rates/briefcase";
export const rutaAgregarCotizacion = "/save-marker-rates/:id";
export const rutaUnidades = "/settings/measures";
export const rutaCotizacionEstado = "/marker-rates/settings/status";
