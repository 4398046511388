import produce from "immer";
import { GENERAL_GET, GENERAL_SET } from "Redux/actions";

const initial_state = { logo: Date.now() };

const General = (state = initial_state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case GENERAL_GET:
        break;
      case GENERAL_SET:
        draft[action.key] = action.value;
        break;
    }
  });
};
export default General;
