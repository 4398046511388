import React from "react";
import { Popover, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;

export default function HelpModule({ message }) {
  return (
    <Popover
      content={<Paragraph>{message}</Paragraph>}
      title=""
      trigger="hover"
    >
      <QuestionCircleOutlined
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </Popover>
  );
}
