import React, { useState, useEffect } from "react";
import {
  Statistic,
  Row,
  Col,
  Button,
  Card,
  Typography,
  message,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import {
  ArrowRightOutlined,
  ToolFilled,
  CalendarFilled,
} from "@ant-design/icons";
import CustomerTag from "components/customer/Tag";
import API from "Util/Axios";
import { pathMyEquipments, pathMyMaintenances } from "Path";

const { Text, Title } = Typography;

const CustomerDashboard = ({ customer }) => {
  const [equipmentCount, setEquipmentCount] = useState([0, 0, 0, 0]);
  const [loadingEquipmentCount, setloadingEquipmentCount] = useState(false);

  const loadEquipmentCount = () => {
    setloadingEquipmentCount(true);
    API(`equipment/customer/${customer}/statistic`)
      .then((r) => {
        setloadingEquipmentCount(false);
        const ok = r.data.some((e) => e._id == 1)
          ? r.data.find((e) => e._id == 1).count
          : 0;
        const warning = r.data.some((e) => e._id == 2)
          ? r.data.find((e) => e._id == 2).count
          : 0;
        const danger = r.data.some((e) => e._id == 3)
          ? r.data.find((e) => e._id == 3).count
          : 0;
        setEquipmentCount([0, ok, warning, danger]);
      })
      .catch((error) => {
        message.error(
          "No se cargaron las estadisticas, recarge el navegador",
          2000
        );
      });
  };

  useEffect(() => {
    loadEquipmentCount();
  }, []);

  return (
    <>
      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Title level={3}>
          <CustomerTag _id={customer} mode="text" text={{ strong: true }} />
        </Title>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              valueStyle={{ color: "#17A589" }}
              title="Equipos funcionando"
              loading={loadingEquipmentCount}
              value={equipmentCount[1]}
              precision={0}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              valueStyle={{ color: "#F4D03F" }}
              title="Equipos con novedad"
              loading={loadingEquipmentCount}
              value={equipmentCount[2]}
              precision={0}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              valueStyle={{ color: "#E74C3C" }}
              title="Equipos fuera de funcionamiento"
              loading={loadingEquipmentCount}
              value={equipmentCount[3]}
              precision={0}
            />
          </Card>
        </Col>
      </Row>
      <br></br>
      <Space>
        <Link to={pathMyEquipments}>
          <Button type="primary">
            Ver mis equipos <ArrowRightOutlined />
          </Button>
        </Link>
        <Link to="/customer/order">
          <Button type="primary">
            Consultar ordenes de mantenimientos <CalendarFilled />
          </Button>
        </Link>
        <Link to={pathMyMaintenances}>
          <Button type="primary">
            Consultar mantenimientos <ToolFilled />
          </Button>
        </Link>
      </Space>
    </>
  );
};

export default CustomerDashboard;
