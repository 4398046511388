/* eslint-disable default-case */
import {
  CUSTOMER_LOADING,
  CUSTOMER_LOAD_SUCCESS,
  CUSTOMER_LOAD_FAIL,
  CUSTOMER_SAVING,
  CUSTOMER_SAVING_SUCCESS,
  CUSTOMER_SAVING_FAIL,
  CUSTOMER_TRASH,
  CUSTOMER_TRASH_SUCCESS,
  CUSTOMER_TRASH_FAIL,
} from "Redux/actions";
import produce from "immer";
const initial_state = {
  docs: [],
  loading: false,
  saving: false,
  error: "",
};
const Customer = (state = initial_state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CUSTOMER_LOADING:
        draft.docs = [];
        draft.loading = true;
        draft.error = "";
        draft.message = "";
        break;
      case CUSTOMER_LOAD_SUCCESS:
        draft.docs = action.docs;
        draft.loading = false;
        break;
      case CUSTOMER_SAVING:
        draft.saving = true;
        draft.error = 0;
        draft.message = "";
        break;
      case CUSTOMER_SAVING_SUCCESS:
        draft.saving = false;
        draft.error = 1;
        var index = draft.docs.findIndex((doc) => doc._id == action.doc._id);
        if (index > -1) {
          console.log("Indx ", index);
          draft.docs[index] = action.doc;
        } else {
          draft.docs.unshift(action.doc);
        }
        break;
      case CUSTOMER_SAVING_FAIL:
        console.log(action.message);
        draft.saving = false;
        draft.error = -1;
        draft.message = action.message;
        break;
      case CUSTOMER_TRASH:
        const index_to_trash = draft.docs.findIndex(
          (doc) => doc._id == action.id
        );
        if (index_to_trash > -1) {
          draft.docs[index_to_trash].deleting = true;
        }
        break;
      case CUSTOMER_TRASH_SUCCESS:
        const index_trashed = draft.docs.findIndex(
          (doc) => doc._id == action.id
        );
        if (index_trashed > -1) {
          draft.docs.splice(index_trashed, 1);
        }
        break;
      case CUSTOMER_TRASH_FAIL:
        const index_not_trashed = draft.docs.findIndex(
          (doc) => doc._id == action.id
        );
        if (index_not_trashed > -1) {
          draft.docs[index_to_trash].deleting = false;
        }
        break;
    }
  });
};

export default Customer;
