import React from "react";
import { Alert, Button } from "antd";
import { Link } from "react-router-dom";

const AccessDenied = ({ msn }) => {
  return (
    <div style={{ padding: 32 }}>
      <Alert message={msn} />
      <Link to="/">
        <Button type="link">Regresar al inicio</Button>
      </Link>
    </div>
  );
};
export default AccessDenied;
