import React, { useEffect, useState } from "react";
import { message, Switch } from "antd";
import { load as loadMarkers } from "Redux/actions/Marker";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import prompt from "antd-prompt";
import API from "Util/Axios";

const MarkerSwitch = ({ markers, loadingMarkers, loadMarkers }) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const location = useLocation();

  const toggle = async (checked) => {
    if (checked) {
      const name = await prompt({
        title: "Ingresa un nombre para tu marcador",
        rules: [
          {
            required: true,
            message: "Debes ingresar un nombre",
          },
        ],
      });
      if (!name.length) {
        return;
      }
      setLoading(true);
      API.post("marker", { url: location.pathname, name })
        .then((r) => {
          loadMarkers();
          message.success("Marcador añadido");
        })
        .catch((error) => {
          setChecked(!checked);
          message.error("No se pudo añadir el marcador");
        })
        .finally(() => setLoading(false));
    } else {
      const marker = markers.find((m) => m.url == location.pathname);
      setLoading(true);
      API.delete(`marker/${marker._id}`)
        .then(() => {
          loadMarkers();
          message.success("Marcador removido");
        })
        .catch((error) => {
          setChecked(!checked);
          message.error("No se pudo remover el marcador");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!loadingMarkers && !markers.length) {
      loadMarkers();
    }
  }, []);

  useEffect(() => {
    setLoading(loadingMarkers);
  }, [loadingMarkers]);

  useEffect(() => {
    if (markers.length) {
      if (markers.some((m) => m.url == location.pathname)) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    } else {
      setChecked(false);
    }
  }, [markers]);

  return (
    <Switch
      loading={loading}
      checked={checked}
      checkedChildren="Favorita"
      unCheckedChildren="No favorita"
      onChange={toggle}
    />
  );
};

const mapToState = (state) => {
  return {
    loadingMarkers: state.Marker.loading,
    markers: state.Marker.docs,
  };
};
const mapToActions = (dispatch) => {
  return {
    loadMarkers: () => {
      dispatch(loadMarkers());
    },
  };
};
export default connect(mapToState, mapToActions)(MarkerSwitch);
