export const USER_ACTION_LOGIN = "USER_ACTION_LOGIN";

export const CUSTOMER_LOADING = "CUSTOMER_LOADING";
export const CUSTOMER_LOAD_SUCCESS = "CUSTOMER_LOAD_SUCCESS";
export const CUSTOMER_LOAD_FAIL = "CUSTOMER_LOAD_FAIL";

export const CUSTOMER_SAVING = "CUSTOMER_SAVING";
export const CUSTOMER_SAVING_SUCCESS = "CUSTOMER_SAVING_SUCCESS";
export const CUSTOMER_SAVING_FAIL = "CUSTOMER_SAVING_FAIL";

export const CUSTOMER_TRASH = "CUSTOMER_TRASH";
export const CUSTOMER_TRASH_SUCCESS = "CUSTOMER_TRASH_SUCCESS";
export const CUSTOMER_TRASH_FAIL = "CUSTOMER_TRASH_FAIL";

export const MY_ORDERS_LOADING = "MY_ORDERS_LOADING";
export const MY_ORDERS_LOAD_SUCCESS = "MY_ORDERS_LOAD_SUCCESS";
export const MY_ORDERS_LOAD_FAIL = "MY_ORDERS_LOAD_FAIL";

export const GENERAL_SET = "GENERAL_SET";
export const GENERAL_GET = "GENERAL_GET";

export const MARKER_LOADING = "MARKER_LOADING";
export const MARKER_LOAD_SUCCESS = "MARKER_LOAD_SUCCESS";
export const MARKER_LOAD_FAIL = "MARKER_LOAD_FAIL";
