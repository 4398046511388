import React from "react";

import Policy from "components/Policy";
import RenderModule from "./renderModule";

export default function RenderModules({ modules, generalPolicies, name }) {
  return (
    <>
      <div style={{ padding: 0 }}>
        <Policy policy={generalPolicies}>
          <div
            style={{
              display: "grid",
              gap: "8px",
              gridTemplateColumns: "repeat(auto-fit,100px)",
              gridTemplateRows: 100,
            }}
          >
            {modules.map((module, i) => (
              <Policy
                policy={module.policy}
                key={`module-${i}`}
                feedback={<></>}
              >
                <RenderModule module={module} />
              </Policy>
            ))}
          </div>
        </Policy>
      </div>
    </>
  );
}
