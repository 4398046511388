import axios from "axios";
import { BASE_URL, API_URL } from "Constants";

export const Axios = axios.create({
  baseURL: BASE_URL,
});

const getToken = () => `Bearer ${localStorage.getItem("token")}`;

const API = axios.create({
  baseURL: API_URL,
});

API.interceptors.request.use(
  function (config) {
    config.headers.common = {
      ...config.headers.common,
      authentication: getToken(),
    };
    return config;
  },
  function (error) {
    // Do something with request error

    return Promise.reject(error);
  }
);

export default API;
