import React, { useContext, useEffect, useState } from "react";
import { Layout, Typography, Tabs } from "antd";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { getUser } from "Util/index";

import dayjs from "dayjs";
import RenderModules from "./renderModules";
import CustomerDashboard from "components/customerDashboard";
import MarkerList from "components/marker/list";

import {
  configModules,
  programmerModules,
  monitoringModules,
  purchasesModules,
  reports,
  customer,
  project,
  design,
  cotizacionModulos,
} from "./modules";
import Policy, { policyAllow } from "components/Policy";
import * as PolicyProvider from "../Policy/Ctx";
import FileEstadistica from "components/FileEstadistica";
const { Title } = Typography;
const { Content, Sider } = Layout;

function Modules(props) {
  const { policies } = useContext(PolicyProvider.Context);

  const [active, setActive] = useState(0);
  const [modulesAllow, setModulesAllow] = useState([]);

  const User = getUser();
  const hour = dayjs().format("HH");
  let msn = "";
  if (hour < 12) {
    msn = "Buenos días, ";
  } else if (hour < 18) {
    msn = "Buenas tardes, ";
  } else {
    msn = "Buenas noches, ";
  }

  const change = (e) => {
    localStorage.setItem("HOME-TAB", e);
    setActive(e);
  };

  useEffect(() => {
    const homeTab = localStorage.getItem("HOME-TAB");
    if (homeTab) {
      setActive(homeTab);
    }
  }, []);

  const policiesMaintenance = [
    "maintenancePlan",
    "maintenanceSchedule",
    "order:admin",
    "roadmap:admin",
  ];
  const policiesPucharse = [
    "costCenter:view",
    "purchase:warehouse",
    "purchase:tools",
    "purchase:material",
    "purchase:orderMaterial",
    "purchase:orderTools",
  ];
  const policiesMonit = ["user:view", "user:monitor", "user:locations"];
  const policiesSettings = [
    "jobPosition:view",
    "equipmentType:view",
    "orderType:view",
    "site:admin",
    "roles:view",
  ];

  useEffect(() => {
    let _modulesAllow = [];
    if (policyAllow(policiesMaintenance, policies)) {
      _modulesAllow.push({
        name: "Mantenimiento",
        key: "a",
        policies: policiesMaintenance,
        modules: programmerModules,
      });
    }
    if (policyAllow(["customer:view"], policies)) {
      _modulesAllow.push({
        name: "Proyectos",
        key: "i",
        policies: ["customer:view"],
        modules: project,
      });
    }
    _modulesAllow.push({
      name: "Diseño",
      key: "design",
      policies: [],
      modules: design,
    });

    if (policyAllow(policiesPucharse, policies)) {
      _modulesAllow.push({
        name: "Compras",
        key: "b",
        policies: policiesPucharse,
        modules: purchasesModules,
      });
    }

    _modulesAllow.push({
      name: "Cotizaciones",
      key: "ab",
      policies: [],
      modules: cotizacionModulos,
    });

    if (policyAllow(["customer:view"], policies)) {
      _modulesAllow.push({
        name: "Clientes y equipos",
        key: "g",
        policies: ["customer:view"],
        modules: customer,
      });
    }
    if (policyAllow(policiesMonit, policies)) {
      _modulesAllow.push({
        name: "Usuarios",
        key: "c",
        policies: policiesMonit,
        modules: monitoringModules,
      });
    }
    if (policyAllow([], policies)) {
      _modulesAllow.push({
        name: "Informes",
        key: "d",
        policies: [],
        modules: reports,
      });
    }
    if (policyAllow(policiesSettings, policies)) {
      _modulesAllow.push({
        name: "Configuraciones",
        key: "e",
        policies: policiesSettings,
        modules: configModules,
      });
    }
    setModulesAllow(_modulesAllow);
  }, [policies]);

  return (
    <>
      <Layout style={{ minHeight: "92vh" }} className="dashboard">
        <Navbar showSwitchMarker={false}></Navbar>

        <Content
          style={{
            padding: 42,
            margin: 0,
          }}
        >
          {User.customer ? (
            <div style={{ margin: 24 }}>
              <CustomerDashboard customer={User.customer} />
            </div>
          ) : null}

          {!User.customer ? (
            <>
              
            
              <Tabs tabPosition="left" onChange={change} activeKey={active}>
                {modulesAllow.map((m) => (
                  <Tabs.TabPane key={m.key} tab={m.name}>
                    <RenderModules
                      generalPolicies={m.policies}
                      modules={m.modules}
                      name={m.name}
                    />
                  </Tabs.TabPane>
                ))}
                <Tabs.TabPane key={"f"} tab="Marcadores">
                  <MarkerList />
                </Tabs.TabPane>
              </Tabs>
             
            </>
          ) : null}
        </Content>
      </Layout>
      <Footer />
    </>
  );
}

export default Modules;
