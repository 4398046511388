import { Card, Col, Row } from "antd";
import Body from "components/Body";
import { useEffect, useState } from "react";
import Filtros from "./Filtros.js";
import API from "Util/Axios";
import { BASE_URL, CDN } from "Constants.js";
import dayjs from "dayjs";

const ArchivoMantenimientos = () => {
  const [cargando, setCargando] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [files, setFiles] = useState([]);

  const cargar = (params) => {
    setCargando(true);
    API("file", { params })
      .then(({ data }) => {
        setFiles(data);
      })
      .finally(() => setCargando(false));
  };

  return (
    <Body style_content={{ padding: 16 }} navbar_content={<></>}>
      <Filtros filtrar={cargar} cargando={cargando} />

      <Row gutter={[8, 8]}>
        {files.map((f) => (
          <Col>
            <Card
              cover={
                <img
                  style={{ maxWidth: "30%" }}
                  src={`${CDN}/${f.path}/${f.name}`}
                />
              }
            >
              <Card.Meta
                title={dayjs(f.createdAt).format("YYYY-MM-DD hh:mm a")}
                description={f?.createdBy?.name && f.createdBy.name}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Body>
  );
};

export default ArchivoMantenimientos;
