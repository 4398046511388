import React, { useEffect } from "react";
import { connect } from "react-redux";
import { UserLogin } from "Redux/actions/User";

function Loading(props) {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.UserLogin(true, localStorage.getItem("token"));
    } else {
      props.UserLogin(false, "");
    }
  });
  return <p>Validando sesion</p>;
}

const mapToAction = (dispatch) => {
  return {
    UserLogin: (authed, token) => {
      dispatch(UserLogin(authed, token));
    },
  };
};
export default connect(null, mapToAction)(Loading);
