import React from "react";
import { Layout, Typography } from "antd";
const { Title, Paragraph, Text, Link } = Typography;

function Footer() {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      <Typography>
        <Paragraph>
          <Text type="secondary">Hecho con ❤️ por BluEstudio</Text>
        </Paragraph>
      </Typography>
    </Layout.Footer>
  );
}

export default Footer;
