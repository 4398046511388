import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { load as loadMarkers } from "Redux/actions/Marker";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const { Text } = Typography;

const MarkerList = ({ markers, loadingMarkers, loadMarkers }) => {
  useEffect(() => {
    if (!loadingMarkers && !markers.length) {
      loadMarkers();
    }
  }, []);

  return (
    <div
      style={{
        display: "grid",
        gap: "8px",
        gridTemplateColumns: "repeat(auto-fit,100px)",
        gridTemplateRows: 100,
      }}
    >
      {markers.map((m) => (
        <Link
          to={m.url}
          className="animate__animated animate__fadeInUp block-module"
        >
          <Text type="secondary">{m.name}</Text>
        </Link>
      ))}
    </div>
  );
};

const mapToState = (state) => {
  return {
    loadingMarkers: state.Marker.loading,
    markers: state.Marker.docs,
  };
};
const mapToActions = (dispatch) => {
  return {
    loadMarkers: () => {
      dispatch(loadMarkers());
    },
  };
};
export default connect(mapToState, mapToActions)(MarkerList);
