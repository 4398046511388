import produce from "immer";
import { USER_ACTION_LOGIN } from "../actions";

const initial_state = {
  validatingToken: true,
  authed: false,
};
const User = (state = initial_state, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case USER_ACTION_LOGIN:
        draft.token = action.token;
        draft.authed = action.authed;
        draft.validatingToken = false;
        break;
    }
  });
};
export default User;
