import React from "react";
import ReactDOM from "react-dom";
// import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

String.prototype.trunc = function (n) {
  return this.substr(0, n - 1) + (this.length > n ? "..." : "");
};

Number.prototype.humanFileSize = function (si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;
  let bytes = this;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

Array.prototype.chunk = function (size) {
  let chunks = Array.from({ length: Math.ceil(this.length / size) }, (v, i) =>
    this.slice(i * size, i * size + size)
  );
  console.log(chunks);
  chunks = chunks.map((c) => {
    if (size > c.length) {
      Array(size - c.length)
        .fill(null)
        .forEach((f) => {
          c.push(f);
        });
    }
    return c;
  });

  return chunks;
};

// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
