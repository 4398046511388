import React from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Typography,
  Row,
  Col,
  Spin,
  notification,
  Card,
  message,
} from "antd";

import { Axios } from "Util/Axios";
import { connect } from "react-redux";
import { UserLogin } from "Redux/actions/User";
import { BASE_URL, CDN } from "Constants";
import Footer from "./Footer";
import { getHttpError } from "Util/index.js";

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const { Text } = Typography;
class Login extends React.Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { UserLogin } = this.props;

    const onFinish = (values) => {
      console.log("Success:", values);
      Axios.post("login", values)
        .then((response) => {
          localStorage.setItem("token", response.data);
          UserLogin(true, response.data);
        })
        .catch((error) => {
          UserLogin(false, "");
          message.error(getHttpError(error));
        });
    };

    return (
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: "#D6DBDF" }}
      >

        <Row justify="center">
          <Col xs={20} md={10} lg={6}>
          <div
          style={{
            margin: "0 auto",
            backgroundColor: "#ffff",
            padding: 16,
            borderRadius: 4,
          }}
        >
          <img
            src={`${CDN}/website/logo_color.png?${Date.now()}`}
            style={{
              width: 200,
              margin: "0 auto",
              display: "block",
              marginBottom: 64,
            }}
          />
          <Spin spinning={loading} delay={500}>
            <Form
              name="basic"
              initialValues={{
                remember: true,
                email: "",
                clave: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su E-mail",
                  },
                ]}
              >
                <Input type="email" placeholder="E-mail" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su contraseña!",
                  },
                ]}
              >
                <Input.Password placeholder="Contraseña" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" block htmlType="submit">
                  Acceder
                </Button>
              </Form.Item>
            </Form>
          </Spin>
          <Footer />
        </div>
          </Col>
        </Row>
      
      </div>
    );
  }
}

const mapToAction = (dispatch) => {
  return {
    UserLogin: (authed, token) => {
      dispatch(UserLogin(authed, token));
    },
  };
};

export default connect(null, mapToAction)(Login);
