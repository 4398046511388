import { notification } from "antd";
import API from "./Axios";
import produce from "immer";



export const notify = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export const invertColor = (hex, bw) => {
  try {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  } catch (error) {
    return "#0000";
  }
};

export const loadResource = (self, resource, state) => {
  return new Promise(async (resolve, reject) => {
    try {
      const items = await API.get(resource);
      self.setState((prev) => ({ ...prev, [state]: items.data }));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const saveResource = (self, doc) => {
  self.setState(
    produce((draft) => {
      const index = draft.docs.findIndex((d) => d._id == doc._id);
      if (index > -1) {
        draft.docs[index] = doc;
      } else {
        draft.docs.unshift(doc);
      }
    })
  );
};

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const getUser = () => {
  var base64Url = localStorage.getItem("token").split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
export const getNameThumb=(filename)=>{
  const ext=filename.split('.').pop();
  return filename.replace('.'+ext,'_thumb.'+ext)
}

export const getExtensionFile=(filename)=>{
  return filename.split('.').pop();
}

export const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
export const randomRGB = () =>
  `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

export const getHttpError=(error)=>{
  return error?.response?.data?.detail || error?.response?.data?.errors?.map(e => e.detail + '\n')?.join("\n") || error.toString()
}
