import React from "react";
import {
  Button,
  Space,
  Breadcrumb,
} from "antd";
import {
  HomeOutlined,
  SyncOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import BreadcrumbMaterial from "ComponentsGlobal/BreadcrumbMaterial";
import Policy from "components/Policy";

const Navbar = ({  onNuevo,onReload }) => {
  return (<>
    <Space align="center" style={{ marginRight: "auto" }}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">
            <HomeOutlined /> Inicio
          </Link>
          </Breadcrumb.Item>
        <Breadcrumb.Item>Refrigerantes</Breadcrumb.Item>
      </Breadcrumb>
    </Space>
    <Space style={{ marginLeft: 16 }}>
      <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={onNuevo} style={{color:'whitesmoke'}}
        >Nuevo</Button>

<Button
          type="link"
          icon={<SyncOutlined />}
          onClick={onReload} style={{color:'whitesmoke'}}
        >Recargar</Button>
    </Space>
    </>
  );
};

export default Navbar;
