import React from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Typography } from "antd";
import HelpModule from "./help";
import BadgeModule from "./BadgeModule";

const { Title, Text } = Typography;

export default function RenderModule({ module }) {
  if (!module.disabled) {
    return (
      <Link
        to={module.href}
        className="animate__animated animate__fadeInUp  block-module"
      >
        <BadgeModule {...module} />
        <img src={module.img} className="module-icon" />
        <Text type="secondary">{module.name}</Text>

        {module.help && <HelpModule message={module.help} />}
      </Link>
    );
  }
  return "";
}
