import React from "react";
import { Layout } from "antd";
import Footer from "components/Footer";
import Navbar from "components/Navbar";

export default function Body(props) {
  return (
    <>
      <Layout style={{ minHeight: "100%" }}>
        <Navbar>{props.navbar_content}</Navbar>
        <div style={props.style_content}>{props.children}</div>
      </Layout>
      <Footer />
    </>
  );
}
