import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  pathConfig,
  pathMaterial,
  pathOrderMaterial,
  pathRoadmap,
  pathToolOrder,
  pathTools,
} from "Path";

export default function BreadcrumbMaterial() {
 
  const items=[
    {
      key:1,
      label: <Link to={pathOrderMaterial}>Solicitudes de material</Link>
    },
    {
      key:2,
      label: <Link to={pathMaterial}>Materiales</Link>
    },
    {
      key:3,
      label:<Link to={pathTools}>Herramientas</Link>
    },
    {
      key:4,
      label:<Link to={pathToolOrder}>Solicitudes de Herramientas</Link>
    }
  ]
  return (
    <Dropdown menu={{items}}>
      <a>
        Compras <DownOutlined />
      </a>
    </Dropdown>
  );
}
