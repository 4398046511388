import produce from 'immer';
import {
  MY_ORDERS_LOADING,
  MY_ORDERS_LOAD_SUCCESS,
  MY_ORDERS_LOAD_FAIL
} from 'Redux/actions';

const initial_state = {
  docs: [],
  loading: false
};

const MyOrders = (state = initial_state, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MY_ORDERS_LOADING:
        draft.docs = [];
        draft.loading = true;
        break;
      case MY_ORDERS_LOAD_SUCCESS:
        draft.docs = action.docs;
        draft.loading = false;
        break;
      case MY_ORDERS_LOAD_FAIL:
        draft.loading = false;
        break;
    }
  });
};
export default MyOrders;
