export const BASE_URL = process.env.REACT_APP_API;
export const API_URL = process.env.REACT_APP_API + "/api";
export const URL_SOCKET = process.env.REACT_APP_SOCKET;

export const PADZERO = 6;
export const type_control = [
  "Termostato programable",
  "Termostato no programable",
  "Termostato análogo",
  "Centralizado",
  "Tablero",
  "PLC",
  "Control remoto",
];

export const type_activitys = [
  { value: 1, label: "Caja de texto" },
  { value: 2, label: "Caja númerica" },
  { value: 3, label: "Casilla de verificación" },
  { value: 4, label: "Area de texto" },
];

export const stateOrder = [
  { value: 1, label: "Abierta" },
  { value: 2, label: "En ejecución" },
  { value: 3, label: "Finalizada" },
  { value: 4, label: "Pendiente por cotizar" },
  { value: 5, label: "Cotizada" },
  { value: 6, label: "Cerrada" },
  { value: 0, label: "Anulada" },
];

export const COLOR_DANGER = "#C0392B";
export const COLOR_SUCCESS = "#27AE60";
export const COLOR_WARNING = "#F1C40F";

export const ORDER_PENDIENT = 1;
export const ORDER_IN_PROGRESS = 2;
export const ORDER_DONE = 3;
export const ORDER_PENDIENT_QUOTATION = 4;
export const ORDER_QUOTATION_DONE = 5;
export const ORDER_CLOSED = 6;
export const ORDER_CANCELED = 0;

export const QUOTATION_PENDIENT = 1;
export const QUOTATION_DONE = 2;

export const ROOM_GEO_USERS = "ROOM_GEO_USERS";
export const ROOM_UPDATE_USER = "ROOM_UPDATE_USER";

export const MEASURES = [
  { value: "u", label: "Unidad" },
  { value: "kg", label: "Kilogramos" },
  { value: "lb", label: "Libras" },
  { value: "oz", label: "Onzas" },
  { value: "gr", label: "Gramos" },
  { value: "l", label: "Litros" },
  { value: "ml", label: "Mililitros" },
  { value: "m", label: "Metros" },
];

export const ORDER_MATERIAL_STATE = [
  "Por aprobar",
  "Aprobado",
  "En proceso de compra",
  "Aprobar entrega",
  "En transporte",
  "Entregado",
];

export const MATERIAL_STATE_IN_ORDER = [
  "Pendiente",
  "Entrega total",
  "Entrega parcial",
  "Aprobar entrega",
  "Recibido parcial",
  "Recibido total",
];

export const EQUIPMENT_STATE = [
  { value: 1, label: "Funcionando correctamente", color: "#52BE80" },
  { value: 2, label: "Con novedad", color: "#F1C40F" },
  { value: 3, label: "Fuera de funcionamiento", color: "#E74C3C" },
];

export const PROJECY_ACTIVITY_STATE = [
  { value: 1, label: "A tiempo", color: "#5DADE2" },
  { value: 2, label: "Finalizado", color: "#52BE80" },
  { value: 4, label: "En progreso", color: "#F39C12" },
  { value: 3, label: "Retrasado", color: "#E74C3C" },
];
export const PROJECT_STATE = [
  { value: 0, label: "Pendiente", color: "#7F8C8D" },
  { value: 1, label: "En progreso", color: "#3498DB" },
  { value: 2, label: "Anulado", color: "#E74C3C" },
  { value: 3, label: "Finalizado", color: "#2ECC71" },
  { value: 4, label: "Retrasado", color: "#E74C3C" },
];

export const TOOL_STATE = [
  { value: 1, label: "Disponible", color: "#52BE80" },
  { value: 2, label: "En uso", color: "#3498DB" },
  { value: 3, label: "Averiada", color: "#E74C3C" },
  { value: 4, label: "En mantenimiento", color: "#F1C40F" },
  { value: 5, label: "Dada de baja", color: "#808B96" },
];

export const TOOL_ORDER_STATE = [
  { value: 1, label: "Pendiente", color: "#F7DC6F" },
  { value: 2, label: "Aprobada", color: "#5DADE2" },
  { value: 3, label: "Entregada", color: "#99A3A4" },
  { value: 4, label: "Retornada", color: "#2ECC71" },
  { value: 0, label: "Anulada", color: "#17202A" },
];

export const TIPOS_DE_COTIZACIONES = [
  { value: "M", label: "Mantenimiento preventivo" },
  { value: "C", label: "Mantenimiento correctivo" },
  { value: "P", label: "Proyecto" },
];


export const CDN=process.env.REACT_APP_CDN
console.log({CDN})